/* Grid to layout the one time payment and subscription payment options */
.paywall-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;
}

/* Style for the cards */
.card {
    background-color: #204d74;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    color: yellow;
}

.card:hover {
    text-decoration: none;
    background-color: #f1f1f1;
    color: #2070ba;
}

/* Style for the headings */
.card h3 {
    font-size: 18px;
    margin-bottom: 10px;
    text-decoration: none;
}

/* Style for the paragraphs */
.card p {
    font-size: 14px;
    text-decoration: none;
}

.what-you-get {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #204d74;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    color: yellow;
    margin: 1rem 0;
}