.menu-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: yellow;
    justify-content: center;
    color: #2070ba;
    padding: 10px;
    height: 60px;
    width: 60px;
    border-radius: 8px;
}

.menu-button:hover {
    text-decoration: none;
    background-color: #f5f5f5;
    color: #2070ba;
}

.flyout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}