/* Style for the container */
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

/* Style for the cards */
.card {
    background-color: #204d74;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    color: yellow;
}

.card:hover {
    text-decoration: none;
    background-color: #f1f1f1;
    color: #2070ba;
}

/* Style for the headings */
.card h3 {
    font-size: 18px;
    margin-bottom: 10px;
    text-decoration: none;
}

/* Style for the paragraphs */
.card p {
    font-size: 14px;
    text-decoration: none;
}