.symbol-search-view {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.symbol-search-view .search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    height: 40px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e5e5e5;
}

.symbol-search-view .search-bar .search-input {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 5px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 14px;
}

.symbol-search-view .search-bar .search-input:focus {
    outline: none;
}

.symbol-search-view .search-bar .search-input::placeholder {
    color: #999;
}

.symbol-search-view .search-bar .search-input::-webkit-input-placeholder {
    color: #999;
}

.symbol-search-view .search-bar .search-input::-moz-placeholder {
    color: #999;
}

.symbol-search-view .search-bar .search-input:-ms-input-placeholder {
    color: #999;
}

/* Grid for the search results */
.symbol-search-view .search-results {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    height: calc(100% - 40px);
    overflow: auto;
}

.symbol-search-view .search-results .search-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    width: 40px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e5e5e5;
}