.container {
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.btn-default {
    display: inline-block;
    font-size: 19px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #204d74;
    color: yellow;
    transition: all 0.3s ease-in-out;
}

.btn-default:hover {
    background-color: #f5f5f5;
    color: #204d74;
}

.btn-danger {
    display: inline-block;
    font-size: 19px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #dc3545;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.btn-danger:hover {
    background-color: #f5f5f5;
    color: #dc3545;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

p {
    margin-top: 20px;
    font-size: 16px;
}

a {
    color: #204d74;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.bottom-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #204d74;
    color: yellow;
    font-size: 16px;
    font-weight: bold;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 100;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.spacer {
    flex: 1;
}

/* Styling the select element */
select {
    /* Customizing the appearance */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 16px;
    border: 2px solid #204d74;
    border-radius: 12px;
    background-color: #fff;
    color: #204d74;
    font-size: 14px;
    background-image: linear-gradient(45deg, transparent 50%, #333 50%), linear-gradient(135deg, #204d74 50%, transparent 50%);
    background-position: calc(100% - 12px) center, calc(100% - 8px) center;
    background-size: 8px 8px, 8px 8px;
    background-repeat: no-repeat;
}

/* Styling the dropdown list */
select::-ms-expand {
    display: none;
}

/* Styling the selected option */
select option:checked {
    background-color: #f2f2f2;
    font-weight: bold;
}

/* Popover content */
.popover-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Top bar */
.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 20px;
    border-radius: 30px;
    background-color: #204d74;
    color: yellow;
    font-size: 16px;
    font-weight: bold;
}

.top-bar h1 {
    margin: 0;
    margin-right: 20px;
    padding-left: 12px;
}

.top-bar button {
    display: inline-block;
    font-size: 19px;
    font-weight: bold;
    background-color: #204d74;
    color: yellow;
    transition: all 0.3s ease-in-out;
}

.top-bar button:hover {
    background-color: #f5f5f5;
    color: #204d74;
}