.text-area {
    display: block;
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 21pt;
    border: 4px solid #204d74;
    border-radius: 12px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

textarea:focus {
    outline: none;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.flat-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: #204d74;
    color: yellow;
    border: none;
    font-size: 24pt;
    cursor: pointer;
}

.flat-button:hover {
    color: #204d74;
    background-color: gainsboro;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}