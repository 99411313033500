.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2070ba;
  color: yellow;
  padding: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.header a {
  color: yellow;
  text-decoration: none;
}

.header-left h1 {
  position: absolute;
  left: 40px;
  top: 25px;
  font-size: 30pt;
  margin: 0;
}

.header-left p {
  position: absolute;
  left: 40px;
  top: 75px;
  font-size: 10pt;
  margin: 0;
}

.header-right a {
  color: #2070ba;
  background-color: yellow;
  padding: 10px;
  height: 100%;
  width: 100px;
  border-radius: 8px;
  margin-left: 10px;
  text-decoration: bold;
  transition: all 0.3s ease-in-out;
}

.header-right a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
  color: #2070ba;
}

.menu-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #212529;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}