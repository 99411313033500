.grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
}

.page-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}