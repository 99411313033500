.phrase-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 12px;
    color: #ffff00;
    background-color: #204d74;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    height: 110px;
}

.phrase-card:hover {
    color: #204d74;
    background-color: #f5f5f5;
}

.phrase-card p {
    margin: 0;
    font-size: 22pt;
    font-weight: bold;
}

.phrase-card .text {
    display: block;
    align-items: start;
    padding-bottom: 10px;
    height: 30px;
    overflow: hidden;
}